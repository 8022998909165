/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CustomerLogoutDispatcher from 'Store/CustomerLogout/CustomerLogout.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { activeTabType, tabMapType } from 'Type/Account';

import MyAccountTabList from './MyAccountTabList.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/MyAccountTabList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    customerLogout: () => CustomerLogoutDispatcher.prepareRequest({}, dispatch),
    showSuccessMessage: (message) => dispatch(showNotification('success', message)),
    showErrorMessage: (message) => dispatch(showNotification('error', message)),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    )
});

/** @namespace Scandipwa/Component/MyAccountTabList/Container/MyAccountTabListContainer */
export class MyAccountTabListContainer extends PureComponent {
    static propTypes = {
        onSignOut: PropTypes.func,
        customerLogout: PropTypes.func.isRequired,
        showSuccessMessage: PropTypes.func.isRequired,
        showErrorMessage: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired, // Add logout function
        tabMap: tabMapType.isRequired,
        activeTab: activeTabType.isRequired,
        changeActiveTab: PropTypes.func.isRequired
    };

    static defaultProps = {
        onSignOut: () => {}
    };

    containerFunctions = {
        handleLogout: this.handleLogout.bind(this)
    };

    containerProps() {
        const {
            tabMap,
            activeTab,
            changeActiveTab
        } = this.props;

        return {
            tabMap,
            activeTab,
            changeActiveTab
        };
    }

    handleLogout() {
        const { onSignOut, customerLogout, logout } = this.props;
        customerLogout();
        logout();
        onSignOut();
    }

    render() {
        return (
            <MyAccountTabList
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
