/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CustomerLogoutQuery from 'Query/CustomerLogout.query';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { updateCustomerLogoutStatus } from './CustomerLogout.action'; // Renamed action

/**
 * CustomerLogout Dispatcher
 * @class CustomerLogoutDispatcher
 * @namespace Store/CustomerLogout/Dispatcher
 */
export class CustomerLogoutDispatcher {
    /**
     * Prepares the logout request and dispatches actions for loading, success, and failure
     * @param {Object} options - Options to pass to the mutation
     * @param {Function} dispatch - The dispatch function to trigger actions
     */
    prepareRequest(options, dispatch) {
        const mutation = CustomerLogoutQuery.getCustomerLogoutQueryMutation(options);

        // Dispatch loading state before starting the request
        dispatch(updateCustomerLogoutStatus({
            isLoading: true
        }));

        // Perform the mutation request
        return fetchMutation(mutation)
            .then(
                /** @namespace Store/CustomerLogout/Dispatcher/fetchMutationThen */
                (data) => {
                    // Update the loading state
                    dispatch(updateCustomerLogoutStatus({
                        isLoading: false
                    }));

                    // Check if the response is as expected and return result
                    if (data?.data?.revokeCustomerToken?.result) {
                        return { result: true }; // Return result if logout is successful
                    } else {
                        return { result: false }; // Return false if logout failed
                    }
                },
                /** @namespace Store/CustomerLogout/Dispatcher/fetchMutationError */
                (error) => {
                    // Ensure the loading state is updated after error
                    dispatch(updateCustomerLogoutStatus({
                        isLoading: false
                    }));

                    // Handle error response and return result
                    return { result: false, error: getErrorMessage(error) };
                }
            );
    }
}

export default new CustomerLogoutDispatcher();