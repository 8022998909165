/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CUSTOMER_LOGOUT_STATUS = 'UPDATE_CUSTOMER_LOGOUT_STATUS';  // Updated constant

/**
 * Update the customer logout status (isLoading and any other necessary state)
 * @param {Object} data - The status data (e.g., loading state)
 * @namespace Store/CustomerLogout/Action/updateCustomerLogoutStatus
 */
export const updateCustomerLogoutStatus = (data = {}) => ({
    type: UPDATE_CUSTOMER_LOGOUT_STATUS,
    data
});
