/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Query/CustomerLogoutQuery */
export class CustomerLogoutQuery {
    /**
     * Generates the mutation for revoking customer token (logout)
     */
    getCustomerLogoutQueryMutation() {
        return new Field('revokeCustomerToken')
            .addField('result'); // Returns 'true' or 'false'
    }
}

export default new CustomerLogoutQuery();
